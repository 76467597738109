<template>
    <div class="max-w-1440 lg:px-24 px-8 lg:py-16 py-8 my-14 m-auto rounded-3xl text-center bg-card-grey shadow-custom shadow-slate-100">
        <h2 class="text-4xl pb-8">Pricing</h2>
        <p class="text-xl">Running your groups on Mentomo is completely free! We only take a small fee of 10% + VAT from each subscription payment. That’s it—no hidden fees!</p>
    </div>
</template>

<script>
export default {
    name: 'PricingBox'
}
</script>