<template>
    <div class="waitlist-form max-w-1440 lg:px-24 px-8 lg:py-16 py-8 m-auto rounded-3xl bg-gradient-to-r from-pink-500 to-rose-500 text-white"
        id="join">
        <div class="max-w-900 text-center m-auto">
            <form @submit.prevent="submitEmail">
                <h2 class="lg:text-4xl text-3xl text-white">Join the waitlist today and be the first to access Mentomo
                    when it
                    launches.</h2>
                <input class="block max-w-650 w-full px-6 py-4 rounded-3xl mt-10 mb-5 m-auto text-black focus:outline-none"
                    type="email" name="email" placeholder="Your email" v-model="email" required />

                <button type="submit"
                    class="inline-block py-3 px-5 rounded-full text-white bg-black text-lg font-medium border-0 cursor-pointer">Join
                    the waitlist</button>
            </form>
        </div>
        <ThankYouModal :isVisible="isModalVisible" :title="title" :message="message" @update:isVisible="isModalVisible = $event" />
    </div>
</template>

<script>
import axios from 'axios';
import confetti from 'canvas-confetti';
import ThankYouModal from './ThankYouModal.vue';

export default {
    name: 'JoinWaitlistForm',
    components: {
        ThankYouModal
    },
    data() {
        return {
            email: '',
            title: '',
            message: '',
            isModalVisible: false,
        };
    },
    methods: {
        async submitEmail() {
            try {
                const response = await axios.post('.netlify/functions/waitlist', {
                    email: this.email,
                });
                this.title = response.data.message;
                this.message = 'Thank you so much for joining our waitlist! We\'re thrilled to have you on board and appreciate your interest in our platform. If you have any questions or need further information, feel free to reach out to us. We\'re here to help!';
                this.email = ''; // Clear the input field

                // Trigger confetti animation
                this.triggerConfetti();
                this.isModalVisible = true;
            } catch (error) {
                this.title = 'Warning!';
                if (error.response && error.response.data.message) {
                    this.message = `${error.response.data.message}!`;
                } else {
                    this.message = 'An error occurred while submitting your email.';
                }
                this.isModalVisible = true; // Show the modal even if there's an error
            }
        },
        triggerConfetti() {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        },
    },
};
</script>
