<template>
    <div class="relative w-full block text-center h-650 bg-gradient-to-r from-pink-500 to-rose-500">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-900 p-4">
            <h1 class="lg:text-6xl text-4xl lg:leading-large text-white">Place for your group mentoring, coaching, and learning</h1>
            <p class="py-4 lg:text-2xl text-xl text-white opacity-90">Start your own group and make money by teaching and guiding others in areas you’re passionate about.</p>
            <ButtonDefault buttonText="Join the waitlist" @click="scrollToSection" class="mt-2" />
        </div>
    </div>
</template>

<script>
import ButtonDefault from '@/components/ButtonDefault.vue';

export default {
    name: 'HeroImage',

    components: {
        ButtonDefault
    },

    methods: {
        scrollToSection() {
            const section = document.getElementById('join');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>