<template>
    <div class="inline-block py-3 px-5 rounded-full text-white bg-black text-lg font-medium cursor-pointer">{{ buttonText }}</div>
</template>

<script>
export default {
    name: 'ButtonDefault',
    props: {
        buttonText: {
            type: String,
            required: true
        }
    }
}
</script>