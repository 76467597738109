<template>
    <nav class="flex items-center lg:justify-start justify-center h-16 fixed top-0 left-0 right-0 z-50 bg-white p-4 border-b border-card-grey">
        <div class="lg:w-1/4 w-0"></div>

        <div class="text-center lg:w-2/4 w-2/4">
            <h3 class="font-extrabold"><img src="@/assets/media/mentomo.svg" alt="Mentomo" class="h-[65px] m-auto"/></h3>
        </div>

        <div class="lg:flex lg:w-1/4 w-2/4 justify-end hidden">
            <ButtonDefault class="ml-auto" buttonText="Join the waitlist" @click="scrollToSection" />
        </div>
    </nav>
</template>

<script>
import ButtonDefault from '@/components/ButtonDefault.vue';

export default {
    name: 'NavigationMenu',
    components: {
        ButtonDefault
    },
    methods: {
        scrollToSection() {
            const section = document.getElementById('join');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}
</script>