<template>
    <div class="max-w-1440 lg:px-24 px-8 lg:py-16 py-8 my-14 m-auto rounded-3xl shadow-custom shadow-slate-100">
        <h2 class="text-4xl pb-8 text-center">How It Works</h2>

        <div class="flex gap-5 lg:flex-row flex-col">
            <div class="lg:w-1/5 w-full rounded-3xl bg-card-grey p-7">
                <h4
                    class="text-4xl text-left bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text text-transparent font-medium">
                    1</h4>
                <p class="text-xl pt-2 text-secondary-grey">Set up your account.</p>
            </div>
            <div class="lg:w-1/5 w-full rounded-3xl bg-card-grey p-7">
                <h4
                    class="text-4xl text-left bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text text-transparent font-medium">
                    2</h4>
                <p class="text-xl pt-2 text-secondary-grey">Create a group.</p>
            </div>
            <div class="lg:w-1/5 w-full rounded-3xl bg-card-grey p-7">
                <h4
                    class="text-4xl text-left bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text text-transparent font-medium">
                    3</h4>
                <p class="text-xl pt-2 text-secondary-grey">Invite members to your group.</p>
            </div>
            <div class="lg:w-1/5 w-full rounded-3xl bg-card-grey p-7">
                <h4
                    class="text-4xl text-left bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text text-transparent font-medium">
                    4</h4>
                <p class="text-xl pt-2 text-secondary-grey">Share lessons, interact with your community, schedule events.</p>
            </div>
            <div class="lg:w-1/5 w-full rounded-3xl bg-card-grey p-7">
                <h4
                    class="text-4xl text-left bg-gradient-to-r from-pink-500 to-rose-500 bg-clip-text text-transparent font-medium">
                    5</h4>
                <p class="text-xl pt-2 text-secondary-grey">Earn.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks'
}
</script>