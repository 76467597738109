import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import './assets/tailwind.css';

// Create the Vue app
const app = createApp(App);

// Create the head manager
const head = createHead();

// Use the head manager
app.use(head);

// Use the router
app.use(router);

// Mount the app
app.mount('#app');