<template>
    <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        @click="hide">
        <div class="bg-white p-6 rounded-3xl max-w-lg shadow-lg relative" @click.stop>
            <h2 class="text-2xl font-semibold mb-4">{{ title }}</h2>
            <p class="mb-4 text-secondary-grey">{{ message }}</p>
            <button class="bg-black text-white px-4 py-2 rounded-full" @click="hide">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ThankYouModal',
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
    },
    methods: {
        hide() {
            this.$emit('update:isVisible', false);
        },
    },
};
</script>