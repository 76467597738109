<template>
    <div class="not-found">
        <h1 class="text-6xl py-2">404</h1>
        <p class="text-2xl mb-2">Page Not Found</p>
        <router-link to="/" class="inline-block py-3 px-5 mt-8 rounded-full text-white bg-black text-lg font-medium cursor-pointer">Go to Home</router-link>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';

export default {
    name: 'NotFound',

    setup() {
        useHead({
            title: 'Mentomo | Page not found',
            meta: [
                { name: 'description', content: 'Page not found' },
                { name: 'keywords', content: '' },
                { property: 'og:title', content: 'Mentomo | Page not found' },
                { property: 'og:description', content: 'Page not found' },
                { name: 'robots', content: 'noindex, nofollow' }
            ]
        });
    }
}
</script>

<style scoped>
.not-found {
    text-align: center;
    padding: 50px;
}
</style>