<template>
    <footer class="w-full h-16 p-4 mt-8 mb-8 flex justify-center items-center text-center">
        <div class="flex space-x-8 items-center">
            <a class="rounded-lg py-1 px-2 hover:bg-slate-100" href="https://www.instagram.com/mentomo_com" target="_blank"><span
                    class="fa-brands fa-instagram text-3xl"></span></a>
            <a class="rounded-lg py-1 px-2 hover:bg-slate-100" href="https://www.facebook.com/profile.php?id=61565094088738" target="_blank"><span
                    class="fa-brands fa-facebook text-3xl"></span></a>
            <a class="rounded-lg py-1 px-2 hover:bg-slate-100" href="https://x.com/mentomo_com" target="_blank"><span
                    class="fa-brands fa-x-twitter text-3xl"></span></a>
            <a class="rounded-lg py-1 px-2 hover:bg-slate-100" href="mailto:info@mentomo.com"><span class="fa-regular fa-envelope text-3xl"></span></a>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterMenu'
}
</script>