<template>
    <HeroImage />

    <div class="main">
        <div class="w-full text-center pt-14">
            <h2 class="text-4xl">Features</h2>
        </div>

        <CardTextLeft cardType="Groups" cardTitle="Start your own group"
            cardDescription="Inside the groups you can share lessons, resources, engage with your community, schedule events, and more."
            cardImage="group.png" maxWidth="400" />

        <CardTextRight cardType="Lessons" cardTitle="Create and share lessons"
            cardDescription="Boost member engagement by sharing regular, trend-focused lessons and resources that address your community's needs."
            cardImage="lesson.png" />

        <CardTextLeft cardType="Community" cardTitle="Connect and engage with your community"
            cardDescription="Make your group an engaging and supportive space, where people learn together and help each other grow."
            cardImage="community.png" />

        <CardTextRight cardType="Events" cardTitle="Schedule events"
            cardDescription="Easily create, schedule and organize group events with our event planner." 
            cardImage="event.png" />

        <CardTextLeft cardType="Diary" cardTitle="Progress tracking and self reflection"
            cardDescription="Everyone can track their own progress, reflect, and get weekly summaries with the built-in diary."
            cardImage="diary.png" />

        <CardTextRight cardType="Payments" cardTitle="Subscription payments"
            cardDescription="Make money by charging for group memberships."
            cardImage="payments.png" maxWidth="260"/>

        <HowItWorks/>

        <PricingBox />

        <FaqBox />

        <JoinWaitlistForm />

        <FooterMenu />
    </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import HeroImage from '@/components/HeroImage.vue';
import CardTextRight from '@/components/CardTextRight.vue';
import CardTextLeft from '@/components/CardTextLeft.vue';
import PricingBox from '@/components/PricingBox.vue';
import FaqBox from '@/components/FaqBox.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import JoinWaitlistForm from '@/components/JoinWaitlistForm.vue';
import FooterMenu from '@/components/FooterMenu.vue';

export default {
    name: 'HomePage',
    components: {
        HeroImage,
        CardTextRight,
        CardTextLeft,
        PricingBox,
        FaqBox,
        HowItWorks,
        JoinWaitlistForm,
        FooterMenu
    },

    data() {
        return {
            showElement: true
        };
    },

    setup() {
        useHead({
            title: 'Mentomo | Place for your group mentoring, coaching, and learning',
            meta: [
                { name: 'description', content: 'Mentomo is a safe space for your group mentoring, coaching, and learning. Start your own group and make money by guiding others in areas you’re passionate about.' },
                { name: 'keywords', content: 'Build online communities, Group mentorship, Monetize your mentoring, Online learning communities, Knowledge sharing platform, Collaborative learning platform, Self-development, Mentoring, Coaching' },
                { property: 'og:title', content: 'Mentomo | Place for your group mentoring, coaching, and learning' },
                { property: 'og:description', content: 'Mentomo is a safe space for your group mentoring, coaching, and learning. Start your own group and make money by guiding others in areas you’re passionate about.' }
            ]
        });
    }
}

</script>