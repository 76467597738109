<template>
    <div class="max-w-1440 lg:px-24 px-8 lg:py-16 py-8 my-14 m-auto rounded-3xl shadow-custom shadow-slate-100">
        <h2 class="text-4xl pb-8 text-center">FAQ</h2>

        <div class="max-w-1440">
            <div v-for="(faq, index) in faqs" :key="index" class="max-w-650 p-6 rounded-3xl m-auto bg-card-grey mb-4">
                <div class="flex items-center cursor-pointer" @click="toggleAnswer(index)">
                    <h4 class="w-4/5 text-xl font-medium">{{ faq.question }}</h4>
                    <span :class="getIconClass(index)" class="w-1/5 text-2xl text-black text-right"></span>
                </div>

                <transition name="faq-answer">
                    <p v-if="isVisible(index)" class="pt-4 text-secondary-grey">
                        {{ faq.answer }}
                    </p>
                </transition>
            </div>

            <p class="w-full max-w-900 m-auto text-center text-secondary-grey mt-14">If you have any other questions or need assistance, don’t hesitate to reach out to us at <a class="underline" href="mailto:info@mentomo.com">info@mentomo.com</a>. We’d love to hear from you and will be happy to assist!</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaqBox',
    data() {
        return {
            faqs: [
                {
                    question: 'Who is Mentomo for?',
                    answer: 'Mentomo is for both people looking for mentorship or guidance and those looking to share their expertise and help others. Whether you\'re an experienced professional, an artist, a fitness enthusiast, a therapist, or someone with knowledge in tech, business, self-development, or any other field, Mentomo is a place for you. It’s designed for anyone who wants to create meaningful mentoring relationships, foster community learning, and guide others in areas you’re passionate about.'
                },
                {
                    question: 'What makes Mentomo different from other platforms?',
                    answer: 'What sets Mentomo apart from traditional course or e-learning platforms is our focus on a mentoring-style approach that prioritizes the unique needs and goals of your community. Unlike pre-recorded courses that are more suitable for general knowledge or skill-building, Mentomo empowers you to create and share personalized, real-time lessons tailored to your community’s current challenges, interests, and trends. This platform is designed for those seeking a more interactive and responsive learning experience—whether it’s a group of aspiring entrepreneurs guided by a seasoned business leader or professionals looking to refine their soft skills. By engaging directly with your mentees, you can offer immediate insights, address specific questions, and foster meaningful, long-term relationships that go beyond the limits of traditional online courses.'
                },
                {
                    question: 'What type of content can I share in my group?',
                    answer: 'You can share content in the form of lessons, which may include rich text, videos, images, code, math formulas, and more. The only restriction is on content that is inappropriate, including material that is adult, violent, or otherwise harmful or offensive.'
                }
                // Add more FAQ items here
            ],
            visibleIndex: null  // Track which FAQ is currently visible
        };
    },
    methods: {
        toggleAnswer(index) {
            this.visibleIndex = this.visibleIndex === index ? null : index;
        },
        isVisible(index) {
            return this.visibleIndex === index;
        },
        getIconClass(index) {
            return this.isVisible(index) ? 'fas fa-minus' : 'fas fa-plus';
        }
    }
};
</script>
