<template>
    <div class="flex lg:flex-row flex-col gap-10 items-center max-w-1440 lg:px-24 px-8 lg:py-16 py-8 my-14 m-auto rounded-3xl bg-card-grey shadow-custom shadow-slate-100">
       <div class="lg:w-1/2 w-full">
            <img :src="requireImageSrc" :alt="cardType" class="w-full m-auto block" :style="{ maxWidth: maxWidth + 'px' }" />
        </div>

        <div class="lg:w-1/2 w-full">
            <CardTypeButton :cardTypeText="cardType" />
            <h2 class="text-3xl lg:py-8 py-4">{{ cardTitle }}</h2>
            <p class="text-xl text-secondary-grey">{{ cardDescription }}</p>
        </div>
    </div>
</template>

<script>
import CardTypeButton from '@/components/CardTypeButton.vue';

export default {
    name: 'CardTextLeft',
    props: {
        cardType: {
            type: String,
            required: true
        },
        cardTitle: {
            type: String,
            required: true
        },
        cardDescription: {
            type: String,
            required: true
        },
        cardImage: {
            type: String,
            required: true
        },
        maxWidth: {
            type: String,
            default: '550px'
        }
    },
    components: {
        CardTypeButton
    },
    computed: {
        requireImageSrc() {
            return require(`@/assets/media/${this.cardImage}`);
        }
    }
}
</script>
