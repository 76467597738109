<template>
  <NavigationMenu />
  <router-view></router-view>
</template>

<script>
import NavigationMenu from './components/NavigationMenu.vue';

export default {
  name: 'App',
  components: {
    NavigationMenu
  }
}
</script>
