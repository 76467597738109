<template>
    <div class="inline-block rounded-full text-lg px-4 py-2 text-white bg-gradient-to-r from-pink-500 to-rose-500">{{ cardTypeText }}</div>
</template>

<script>
export default {
    name: 'CardTypeButton',
    props: {
        cardTypeText: {
            type: String
        }
    }
}
</script>